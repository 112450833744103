{
  "units": [
    {
      "Value": "Length",
      "Units": "meter (m), inch (in), foot (ft), yard (yd), mile (mi), link (li), rod (rd), chain (ch), angstrom, mil"
    },
    {
      "Value": "Time",
      "Units": "second (s, secs, seconds), minute (min, mins, minutes), hour (h, hr, hrs, hours), day (days), week (weeks), month (months), year (years), decade (decades), century (centuries), millennium (millennia)"
    },
    {
      "Value": "Surface area",
      "Units": "m2, sqin, sqft, sqyd, sqmi, sqrd, sqch, sqmil, acre, hectare"
    },
    {
      "Value": "Volume",
      "Units": "m3, litre (l, L, lt, liter), cc, cuin, cuft, cuyd, teaspoon, tablespoon"
    },
    {
      "Value": "Mass",
      "Units": "gram(g), tonne, ton, grain (gr), dram (dr), ounce (oz), poundmass (lbm, lb, lbs), hundredweight (cwt), stick, stone"
    },
    {
      "Value": "Binary",
      "Units": "bits (b), bytes (B)"
    },
    {
      "Value": "Temperature",
      "Units": "kelvin (K), celsius (degC), fahrenheit (degF), rankine (degR)"
    },
    {
      "Value": "Electricity and magnetism",
      "Units": "ampere (A), coulomb (C), watt (W), volt (V), ohm, farad (F), weber (Wb), tesla (T), henry (H), siemens (S), electronvolt (eV)"
    },
    {
      "Value": "Power",
      "Units": "watt (W), hp"
    },
    {
      "Value": "Angles",
      "Units": "rad (radian), deg (degree), grad (gradian), cycle, arcsec (arcsecond), arcmin (arcminute)"
    }
  ]
}
