{
  "shortcuts": [
    {
      "Action": "Paste the last result",
      "Shortcut": "Ctrl + Shift + Enter / Command + Shift + Enter"
    },
    {
      "Action": "Copy the last result",
      "Shortcut": "Ctrl + Enter / Command + Enter"
    },
    {
      "Action": "Open/Close help",
      "Shortcut": "Ctrl + ?"
    },
    {
      "Action": "Open/Close calculator History",
      "Shortcut": "Ctrl + H"
    }
  ]
}
